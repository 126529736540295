body {
  color: #666b85;
  font-family: 'Roboto'; }

a, a:focus, a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal; }

p {
  margin: 0; }

input[type=checkbox], input[type=radio] {
  margin: 0;
  cursor: pointer; }

hr {
  margin: 0; }

label {
  font-weight: 200;
  font-size: 16px; }

ul, ol {
  padding-left: 0;
  margin: 0;
  list-style-type: none; }

i, cite, em, var, address, dfn {
  font-style: normal; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

textarea {
  resize: vertical; }

select {
  cursor: pointer; }

input, button, textarea, select {
  border: none;
  outline: none;
  background: transparent;
  color: #666b85;
  font-size: inherit;
  padding: 0;
  margin: 0; }
  input:focus, button:focus, textarea:focus, select:focus {
    outline: none; }

button {
  cursor: pointer; }

label {
  margin-bottom: 0;
  cursor: pointer; }

button, input, optgroup, select, textarea,
td, th {
  text-align: inherit;
  padding: 0; }

table caption {
  padding: 0; }

mark {
  background: none;
  padding: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

.standard-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 10px 20px;
  border-radius: 2px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer; }
  .standard-button.-theme-bg {
    background: var(--color-theme-bg);
    color: white; }
    .standard-button.-theme-bg:hover {
      color: white; }
  .standard-button.-theme-light-outline {
    min-width: 120px;
    background: transparent;
    padding: 10px 30px;
    border: 1px solid var(--color-theme-bg);
    border-radius: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: var(--color-theme-bg); }
  .standard-button.-red {
    background: #f7594a;
    color: white; }
    .standard-button.-red:hover {
      color: white; }
  .standard-button.-blue {
    background: #389aeb;
    color: white; }
    .standard-button.-blue:hover {
      color: white; }
  .standard-button.-green {
    background: #27AE60; }
    .standard-button.-green:hover {
      background: #27AE60;
      color: white; }
    .standard-button.-green:disabled {
      background: #b3b3b3;
      pointer-events: none; }
  .standard-button.-plum {
    background: #d1478c;
    color: white; }
    .standard-button.-plum:hover {
      background: #b83e7b; }
  .standard-button.-dark-gray {
    background: #6B787F;
    color: white; }
  .standard-button.-white {
    justify-content: flex-start;
    background: white;
    color: black;
    padding: 5px 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25); }
    .standard-button.-white:hover {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.35); }
  .standard-button.-sliding {
    position: relative; }
    .standard-button.-sliding:after {
      content: '';
      display: block;
      width: 0px;
      height: 4px;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      transition: width .5s ease, background-color .5s ease; }
    .standard-button.-sliding:hover:after {
      display: none;
      width: 100%;
      background: rgba(0, 0, 0, 0.2); }
  .standard-button.-outline {
    background: transparent;
    border: 1px solid white; }
    .standard-button.-outline.-plum {
      border-color: #d1478c;
      color: #d1478c; }
      .standard-button.-outline.-plum:hover {
        color: white;
        background: #d1478c; }
  .standard-button.-green, .standard-button.-plum, .standard-button.-dark-gray, .standard-button.-outline, .standard-button.-underline {
    min-width: auto;
    border-radius: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: white;
    letter-spacing: 0; }
  .standard-button.-underline {
    background: transparent;
    padding: 0;
    color: var(--color-theme-bg);
    text-decoration: underline;
    text-underline-position: under; }
    .standard-button.-underline:hover {
      color: var(--color-theme-bg-dark); }
  .standard-button.-icon {
    min-width: auto;
    background: transparent !important;
    padding: 0;
    border-radius: 0; }
  .standard-button.-transparent {
    background: transparent;
    padding: 2px 16px; }
  .standard-button.-disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none; }
  .standard-button.-small {
    padding: 4px 8px;
    font-size: 10px; }

.standard-menu-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px; }
  .standard-menu-button.-bulk {
    width: 130px;
    background: white;
    border: 1px solid #E5E5E5;
    color: #333; }
    .standard-menu-button.-bulk > i {
      font-weight: bold;
      font-size: 18px; }

.standard-more-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--color-theme-text); }
  .standard-more-button i {
    margin-left: 4px;
    font-size: 15px; }

.standard-dropdown ul {
  padding: 0; }
  .standard-dropdown ul li {
    height: auto;
    background: transparent !important;
    padding: 10px;
    font-size: 15px;
    line-height: 18px;
    color: #555555; }
    .standard-dropdown ul li:hover {
      color: black; }

.standard-form {
  margin-top: 20px; }
  .standard-form .label-and-input {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 10px; }
    .standard-form .label-and-input > label {
      width: 40%;
      padding-right: 30px;
      text-align: right; }
      .standard-form .label-and-input > label.required {
        font-weight: bold; }
    .standard-form .label-and-input > .input-wrapper {
      width: 60%; }

.read-only-extender.-read-only, .-read-only.standard-input.-TextInput, .-read-only.standard-input.-Textarea, .-read-only.standard-input.-Checkbox {
  background: #efefef;
  cursor: not-allowed;
  pointer-events: none; }

.pretty-input, .standard-input.-TextInput, .standard-input.-Textarea {
  padding: 8px 15px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: inset 0 1px 2px rgba(220, 220, 220, 0.6);
  font-size: 17px;
  font-weight: 300;
  width: 100%;
  max-width: 400px; }

.standard-input.-TextInput:focus {
  border: 1px solid var(--color-theme-text); }

.standard-input.-Textarea.-showing-complex-placeholder {
  color: grey; }

.standard-input.-Textarea:focus {
  border: 1px solid var(--color-theme-text); }

.standard-input.-Checkbox {
  cursor: pointer;
  width: 23px;
  height: 23px; }

.standard-input.-ImageInput {
  max-width: 100%;
  width: 250px;
  height: 200px; }

.standard-label-default, .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-border, .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > h4, .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container ul li, .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.label-and-input input, .standard-search-box .-search_text .input-wrapper input, .standard-label, .standard-radio, .standard-empty-content, .standard-empty-content p {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #555555; }

.standard-modal-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000 !important;
  overflow: hidden;
  position: fixed;
  padding: 20px;
  background: rgba(11, 11, 11, 0.66); }
  .standard-modal-wrapper > .standard-modal {
    max-width: 860px;
    margin: 0 auto;
    margin-top: 150px;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    background: white;
    border-radius: 4px;
    position: relative; }
    .standard-modal-wrapper > .standard-modal > button.close-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 19px; }
      .standard-modal-wrapper > .standard-modal > button.close-button i.fa-remove {
        color: white;
        font-size: 20px; }
    .standard-modal-wrapper > .standard-modal > .standard-modal-header {
      min-height: 42px;
      padding: 15px 30px;
      background: var(--color-theme-bg);
      font-size: 22px;
      text-align: left;
      color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
    .standard-modal-wrapper > .standard-modal > .standard-modal-main {
      padding: 10px 30px; }
    .standard-modal-wrapper > .standard-modal > .standard-modal-spe {
      border-top: 1px solid #e5e5e5;
      padding: 10px 30px; }
    .standard-modal-wrapper > .standard-modal > .standard-modal-footer {
      background: #f1f4f7;
      padding: 10px 30px;
      border-top: 1px solid #e5e5e5; }
      .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        padding-bottom: 20px;
        border: 0; }
        .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button {
          min-width: 200px;
          background: transparent;
          border: 1px solid;
          margin-left: 20px; }
          @media (max-width: 767px) {
            .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button {
              min-width: 100px; } }
          .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-red {
            color: #EB5757; }
            .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-red:hover {
              background: #FAFAFA;
              color: #E62323; }
          .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-blue {
            background: var(--color-theme-bg);
            color: white;
            border: 0; }
            .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-blue:hover {
              background: var(--color-theme-bg-dark);
              color: #FAFAFA; }
              .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-blue:hover:disabled {
                background: transparent; }
            .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-blue:disabled {
              background: #b3b3b3;
              pointer-events: none; }
          .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-border {
            border: 1px solid #4A8DD0;
            margin: 0;
            padding: 9px 0;
            color: #4A8DD0 !important;
            text-align: center; }
            .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-border:hover {
              background: #f9f9f9;
              border-color: #2b75bf;
              color: #2b75bf; }
          .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button::after {
            display: none; }
      .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-vertical-center {
        justify-content: center;
        background: white;
        border-color: #C4C4C4; }
    .standard-modal-wrapper > .standard-modal.-new {
      max-height: 90%;
      margin-top: 0;
      top: 50%;
      transform: translateY(-50%); }
    .standard-modal-wrapper > .standard-modal.-with-white-header {
      width: 770px;
      max-height: 98%;
      margin-top: 0;
      top: 50%;
      background: #f1f4f7;
      transform: translateY(-50%); }
      .standard-modal-wrapper > .standard-modal.-with-white-header > button.close-button {
        padding: 30px; }
        .standard-modal-wrapper > .standard-modal.-with-white-header > button.close-button i {
          color: #555555; }
      .standard-modal-wrapper > .standard-modal.-with-white-header > .standard-modal-header {
        width: 100%;
        background: transparent;
        padding: 30px;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: left;
        color: #555555; }
      .standard-modal-wrapper > .standard-modal.-with-white-header > .standard-modal-main {
        padding: 0 30px; }
    .standard-modal-wrapper > .standard-modal.-with-green-header {
      width: 770px; }
    .standard-modal-wrapper > .standard-modal.-student-modal {
      width: 730px;
      top: 0;
      margin-top: 30px;
      transform: none; }
      .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-header {
        padding-bottom: 12px !important; }
      .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main {
        padding: 0 30px;
        margin-bottom: 15px; }
        .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > h4 {
          margin-bottom: 40px; }
        .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container {
          display: flex;
          justify-content: space-between; }
          .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container h2 {
            margin-bottom: 13px;
            color: #555555; }
          .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container ul {
            border: 1px solid #b3b3b3;
            padding: 17px 15px;
            overflow-y: auto; }
            .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container ul li {
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              line-height: 30px; }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container ul li > div.drag-icon {
                height: 20px; }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container ul li svg {
                margin-right: 6px;
                font-size: 18px;
                color: var(--color-theme-bg); }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container ul li:last-child {
                margin: 0; }
          .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel, .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container div.right-panel {
            width: 282px; }
          .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > div.title-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 13px; }
            .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > div.title-bar > h2 {
              margin-bottom: 0; }
            .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > div.title-bar button.-cancel {
              color: #EB5757; }
          .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form {
            margin-bottom: 44px; }
            .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.label-and-input {
              margin-bottom: 12px; }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.label-and-input > label {
                display: none; }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.label-and-input input {
                padding: 10px; }
                .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.label-and-input input:focus {
                  border: 1px solid var(--color-theme-text); }
            .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > button.btn-form-submit {
              width: 100%;
              border-color: var(--color-theme-text);
              color: var(--color-theme-text); }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > button.btn-form-submit:hover {
                background: #FAFAFA;
                color: #029696; }
            .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.invite-status {
              margin: 5px 0;
              padding: 10px;
              font-weight: bold;
              text-align: center; }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.invite-status.-sent {
                background-color: #dff0d8;
                color: #3c763d; }
              .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.invite-status.-error {
                background: #fbdbdb; }
            .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.invitation-loading {
              margin-top: 5px; }
          .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.right-panel h2 {
            margin-bottom: 13px; }

.standard-dropzone {
  border: 2px dashed var(--color-theme-bg);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 70px; }
  .standard-dropzone:focus {
    outline: none; }
  .standard-dropzone > label {
    width: 100%;
    text-align: center; }

.standard-page-loading.-request {
  font-size: 100px;
  opacity: 0.08;
  margin-top: 150px; }

.standard-article {
  font-size: 18px; }
  .standard-article ol, .standard-article ul {
    padding-left: 25px; }
  .standard-article ol {
    list-style-type: decimal; }
  .standard-article ul {
    list-style-type: disc; }
  .standard-article p {
    margin-top: 5px;
    margin-bottom: 5px; }
  .standard-article a {
    color: var(--color-theme-text); }
  .standard-article em {
    font-style: italic; }

.standard-alert.-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 12px 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  margin: 16px 0; }

.standard-alert-container {
  z-index: 9999 !important; }

.standard-alert-body {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  .standard-alert-body .standard-alert-icon {
    color: white; }
  .standard-alert-body .standard-course-alert {
    margin-left: 10px;
    font-weight: 500;
    color: white; }
  .standard-alert-body.-success {
    background: #43a047; }
  .standard-alert-body.-warning {
    background: #d32f2f; }

.standard-program-dashboard-form-wrapper {
  border: 1px solid #d6dde3; }
  .standard-program-dashboard-form-wrapper > h1.form-heading {
    padding: 20px;
    font-size: 24px;
    font-weight: 300;
    color: var(--color-theme-text);
    border-bottom: 1px solid #e4eaf0; }
  .standard-program-dashboard-form-wrapper > div.form-explanation {
    padding-left: 20px;
    padding-top: 10px;
    font-size: 15px;
    color: #9eabb8;
    letter-spacing: 0.25px; }
  .standard-program-dashboard-form-wrapper > form.form {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 20px; }
    .standard-program-dashboard-form-wrapper > form.form div.label-and-input {
      margin-top: 40px; }
      .standard-program-dashboard-form-wrapper > form.form div.label-and-input:first-child {
        margin-top: 0; }
    .standard-program-dashboard-form-wrapper > form.form button.submit-button {
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 20px; }

.standard-label-default, .standard-modal-wrapper > .standard-modal > .standard-modal-footer.-with-buttons > .standard-button.-border, .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > h4, .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container ul li, .standard-modal-wrapper > .standard-modal.-student-modal > div.standard-modal-main > div.body-container > div.left-panel > form.invitation-form > div.label-and-input input, .standard-search-box .-search_text .input-wrapper input, .standard-label, .standard-radio, .standard-empty-content, .standard-empty-content p {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #555555; }

.standard-navbar {
  margin-top: 30px; }
  .standard-navbar .standard-nav-container, .standard-navbar .standard-nav {
    margin-top: 20px;
    padding-left: 10px;
    white-space: nowrap;
    font-size: 18px;
    line-height: 21px;
    color: #555555;
    cursor: pointer; }
    .standard-navbar .standard-nav-container.-active, .standard-navbar .standard-nav.-active {
      color: var(--color-theme-text);
      border-left: 2px solid var(--color-theme-text); }
    .standard-navbar .standard-nav-container:first-child, .standard-navbar .standard-nav:first-child {
      margin: 0; }

.standard-search-box {
  width: 270px;
  display: flex;
  align-items: center;
  padding: 10px 9px;
  border: 1px solid #b3b3b3; }
  .standard-search-box .search-icon {
    font-size: 18px; }
  .standard-search-box .-search_text {
    width: 100%;
    display: flex;
    margin-left: 17px; }
    .standard-search-box .-search_text label {
      display: none; }
    .standard-search-box .-search_text .input-wrapper {
      width: 100%; }
      .standard-search-box .-search_text .input-wrapper input {
        padding: 0;
        border: 0;
        box-shadow: none; }
  .standard-search-box:focus-within {
    border: 1px solid var(--color-theme-text); }

.standard-table-header-row {
  height: 40px !important;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); }

.standard-table-head-cell {
  padding: 0 0 0 20px !important;
  border: 0; }
  .standard-table-head-cell.-cell-width-cs1 {
    width: 264px; }
  .standard-table-head-cell.-cell-width-cs2 {
    width: 322px; }
  .standard-table-head-cell.-cell-width-cs3 {
    width: 35px;
    padding-top: 3px !important; }
  .standard-table-head-cell:last-child .standard-header-cell-div {
    border: 0 !important; }
  .standard-table-head-cell.-no-padding {
    padding: 0 !important; }

.standard-header-cell-div {
  margin: 5px 0;
  padding: 0 20px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #555555;
  border-right: 1px solid #b3b3b3; }

.standard-table-header-sort-label {
  width: 100%;
  height: 30px;
  margin: 5px 0 !important;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #555555 !important;
  border-right: 1px solid #b3b3b3 !important; }
  .standard-table-header-sort-label:hover svg, .standard-table-header-sort-label.label-active svg {
    color: #555555; }
  .standard-table-header-sort-label.-no-border {
    border: 0 !important; }
  .standard-table-header-sort-label .sort-icon {
    color: #b3b3b3;
    position: absolute;
    right: 0;
    margin: 0 10px;
    opacity: 1 !important; }

.standard-table-body-row td:first-child > div {
  padding-left: 0;
  margin-left: 20px; }

.standard-table-body-row > td:last-child > div {
  margin-right: 20px; }
  .standard-table-body-row > td:last-child > div.inactive {
    color: #b3b3b3; }

.standard-table-body-row.-checkbox td:first-child > div.checkbox-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding: 0 2px 0 8px;
  border-bottom: 1px solid #b3b3b3; }

.standard-table-body-cell {
  padding: 0 !important;
  border: 0 !important;
  height: 49px; }

@-moz-document url-prefix() {
  .standard-table-body-cell {
    height: 100%; } }

.standard-table-cell-div {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 20px;
  word-break: break-word;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #555555;
  border-bottom: 1px solid #b3b3b3; }
  .standard-table-cell-div.-status {
    color: #27AE60; }
    .standard-table-cell-div.-status.-inactive {
      color: #b3b3b3; }
  .standard-table-cell-div.-action {
    padding: 4px 0;
    padding-left: 10px; }

.standard-tab-list {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 0; }

.standard-tab {
  flex: 1;
  background: transparent;
  padding: 11px;
  border-bottom: 2px solid white;
  text-align: center;
  color: #b3b3b3;
  cursor: pointer; }
  .standard-tab:hover {
    color: var(--color-theme-text) !important; }

.standard-tab-selected {
  border-color: var(--color-theme-bg-dark);
  color: var(--color-theme-bg-dark) !important; }

.standard-title {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #555555; }

.standard-modal-title, .standard-nav .standard-nav-item, .standard-title {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #555555; }

.standard-nav .standard-nav-item {
  padding-left: 10px;
  border-left: 2px solid transparent; }
  .standard-nav .standard-nav-item:hover {
    color: var(--color-theme-text); }
  .standard-nav .standard-nav-item.active {
    color: #4a8dd0;
    border-color: #4a8dd0; }

.standard-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .standard-radio.-readonly {
    cursor: default; }
  .standard-radio.-no-label {
    padding: 0; }
  .standard-radio > input[type=radio] {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .standard-radio > input[type=radio]:checked ~ span.radio-checkmark {
      border-color: var(--color-theme-bg); }
      .standard-radio > input[type=radio]:checked ~ span.radio-checkmark::after {
        display: block;
        background-color: var(--color-theme-bg); }
  .standard-radio > span.radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #b3b3b3;
    border-radius: 50%; }
    .standard-radio > span.radio-checkmark::after {
      content: "";
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%; }

.standard-empty-content {
  display: flex;
  align-items: center;
  justify-content: center; }
  .standard-empty-content.-table-content {
    padding: 20px 10px 0 10px; }
    .standard-empty-content.-table-content p {
      width: 100%;
      overflow-wrap: break-word;
      text-align: center; }

.standard-info-tooltip {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
  .standard-info-tooltip i {
    color: transparent; }

.tippy-tooltip-content div {
  font-size: 13px;
  max-width: 220px; }

.standard-dashboard-content.-with-banner {
  margin-top: 100px;
  height: calc(100vh - 100px); }

.standard-dashboard-content.-full-banner {
  margin-top: 130px;
  height: calc(100vh - 130px); }

.standard-dashboard-content.-auto-height {
  height: auto !important; }

@media (max-width: 767px) {
  .standard-dashboard-content.-with-banner {
    margin-top: 80px;
    height: calc(100vh - 80px); }
  .standard-dashboard-content.-full-banner {
    margin-top: 110px;
    height: calc(100vh - 110px); } }

.react-joyride__tooltip button[data-test-id=button-close] svg {
  width: 10px; }
